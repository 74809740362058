<template>
  <a-card :bordered="false">
    <a-card :bordered="false">
      <div slot="extra">
        <a-button type="primary" icon="save" @click="updateFastSetting" style="float:right">保存</a-button>
        <a-button icon="left" @click="goList" style="float:right; margin-right:15px;">返回</a-button>
      </div>
      <div >
        <a-form layout="inline">
          <a-row :gutter="16">
            <a-col :md="11" :sm="24">
              <a-form-item label="配置名称">
                <a-input
                  v-model="fastInfo.name"
                  v-decorator="[
                    'fastInfo.name',
                    {rules: [{ required: true, message: '请输入名称' }]}
                  ]"
                  name="fastInfo.name"
                  placeholder="请输入名称"/>
              </a-form-item>
            </a-col>
            <a-col :md="11" :sm="24">
              <a-form-item label="配置描述">
                <a-input v-model="fastInfo.description" placeholder="" style="width:250px;"/>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>
    <a-card
      style="margin-top: 24px"
      :bordered="false">

      <div slot="extra">
        <a-radio-group v-model="menuSelect" :defaultValue="menuSelect">
          <a-radio-button
            v-for="item in menuList"
            :value="item.value"
            :key="item.id"
            @click="menuChoose(1,item.value)"
            >{{ item.description }}</a-radio-button>

          <a-radio-button
            :value="0"
            :key="0"
            @click="menuChoose(2,null)"
          >非常规指标</a-radio-button>
        </a-radio-group>
        <a-button type="primary" icon="edit" @click="manageIndex" style="margin-left:10px;">管理</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        rowKey="key"
        :columns="columns"
        :data="loadData"
        :alert="false"
        showPagination="auto"
      >

        <template slot="detectionMethodRelaxEntities" slot-scope="text, record, index">
          <a-select @change="onChange(record)"
                    v-model="record.methodId"
                    placeholder="请选择"
                    style="width:200px;"
                    dropdownClassName="select-drop-down"
                    :dropdownMatchSelectWidth="false">
            <a-select-option
                    v-for="option in record.detectionMethodRelaxEntities"
                    :value="option.methodId"
                    :key="option.id"
            >{{ option.method }}</a-select-option>
          </a-select>
        </template>
        <span slot="action" slot-scope="text, record">
          <template>
            <a-switch
              :autoFocus="false"
              :checked="record.check"
              @change="selectChange(record)" >
            </a-switch>
          </template>
        </span>
      </s-table>

    </a-card>
  </a-card>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { getFastDetail, getItemList, addFast, updateFast } from '@/api/quality/item'
import { getMenuList } from '@/api/basic/common'

const columns = [
  {
    title: '序号',
    dataIndex: 'index'
  },
  {
    title: '名称',
    dataIndex: 'descChinese'
  },
  {
    title: '检测方法',
    dataIndex: 'detectionMethodRelaxEntities',
    scopedSlots: { customRender: 'detectionMethodRelaxEntities' }
  },
  {
    title: '是否检测',
    dataIndex: 'action',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis
  },
  data () {
    this.columns = columns
    return {
      // 配置项
      checked: true,
      // 查询参数
      queryParam: {},
      listParam: {
        category: 1,
        type: 1
      },
      baseParam: {
        centerId: 0,
        fastId: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.listParam)
        // console.log('loadData request parameters:', requestParameters)
        return getItemList(requestParameters)
          .then(res => {
            var findList = []

            for (var i = 0;i<res.data.list.length;i++) {
              var value = res.data.list[i]
              // 序号
              value.index = i + 1

              if(value.detectionMethodRelaxEntities != null) {
                for (var entity of value.detectionMethodRelaxEntities) {
                  if(entity.isDefault === 1) {
                    value.methodId = entity.methodId
                  }
                }
              }

              value.detectId = value.id
              findList = []
              findList = this.selectList.filter(function (currentValue, index, ar) {
                return currentValue.detectId === value.id
              })

              console.log(findList)

              if(findList.length > 0)
              {
                value.check = true
                value.methodId = findList[0].methodId
              }

              console.log(value)
            }

            // for (let i = 0; i < list.length; i++) {
            //   list[i].methodId = list[i].detectionMethodRelaxEntities[0].methodId
            //   list[i].detectId = list[i].id
            //   findList = []
            //   findList = this.selectList.filter(function (currentValue, index, ar) {
            //     return ar[index] === list[i].descChinese
            //   })
            //   list[i].check = (findList.length > 0)
            // }

            var data = {
              data: res.data.list,
              pageNo: parameter.pageNumber,
              pageSize: parameter.pageSize,
              totalCount: res.data.total,
              totalPage: parseInt(res.data.total / parameter.pageSize) + 1
            }
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      // 返回数据
      fastInfo: {},
      menuList: [],
      menuSelect: 0,
      selectList: [],
      selectDetail: []
    }
  },
  created () {
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.queryParam.centerId = area.depId

    this.getFastInfo()
  },
  computed: {
  },
  methods: {
    // 请求接口
    getFastInfo () {
      if (this.$route.query.id === '0') {
        this.updateFastSetting()
        this.getMenu()
      } else {
        getFastDetail({ id: this.$route.query.id }).then(response => {
          this.fastInfo = response.data
          var list = this.fastInfo.fastConfigurationInfoEntities
          var selectList = []
          if (list != null) {
            for (let i = 0; i < list.length; i++) {
              selectList.push(list[i])
            }
          }
          this.selectList = selectList
          this.selectDetail = this.fastInfo.fastConfigurationInfoEntities
          this.baseParam.centerId = this.fastInfo.centerId
          this.baseParam.fastId = this.fastInfo.id

          this.getMenu()
          // this.listParam.waterType = response.data.list[0].value
        })
      }
    },
    getMenu () {
      getMenuList({ menu: '指标类型' }).then(response => {
        this.menuList = response.data.list
        this.menuSelect = response.data.list[0].value
        this.listParam.type = response.data.list[0].value
        this.loadData(this.listParam)
        this.$refs.table.refresh(true)
      })
    },
    updateFastSetting () {
      var self = this
      var idData = {
        id: this.$route.query.id
      }
      var centerData = {
        centerId: this.queryParam.centerId
      }
      var formData = {
        description: this.fastInfo.description,
        fastConfigurationInfoEntities: this.fastInfo.fastConfigurationInfoEntities,
        name: this.fastInfo.name
      }
      if (this.$route.query.id === '0') {
        addFast(Object.assign({}, centerData, formData)).then(response => {
          if (response.code === 0){
            this.$router.push({
              path: '/test/setting/edit',
              query: {
                id: response.data
              }
            })
          } else {
            this.$message.error('添加失败')
          }
        })
      } else {
        updateFast(Object.assign({}, idData, formData)).then(response => {
          if (response.code === 0){
            self.$message.info('检测项配置更新保存成功')
            this.loadData(this.listParam)
            this.$refs.table.refresh(true)
          } else {
            this.$message.error('更新失败')
          }
        })
      }
    },
    // 操作方法
    goList () {
      this.$router.back()
    },
    manageIndex () {
      this.$router.push({
        path: '/test/item',
        query: {
          id: 1
        }
      })
    },
    menuChoose (c, v) {
      this.listParam.category = c
      this.listParam.type = v
      this.loadData(this.listParam)
      this.$refs.table.refresh(true)
    },
    onChange(item) {
      for (let i = 0; i < this.fastInfo.fastConfigurationInfoEntities.length; i++) {
        if (this.fastInfo.fastConfigurationInfoEntities[i].detectName === item.descChinese) {
          this.fastInfo.fastConfigurationInfoEntities[i].methodId = item.methodId
        }
      }
    },
    selectChange (item) {
      if (!item.check) {
        var obj = item
        obj.centerId = this.baseParam.centerId
        obj.fastId = this.baseParam.fastId
        if (this.fastInfo.fastConfigurationInfoEntities === null) {
          this.fastInfo.fastConfigurationInfoEntities = []
        }
        this.fastInfo.fastConfigurationInfoEntities.push(obj)
        this.selectList.push(item)

        this.updateFastSetting()
      } else {
        var name = item.descChinese
        // 从selectList 删除
        var sList = []
        var fList = []
        for (let i = 0; i < this.selectList.length; i++) {
          if (this.selectList[i].descChinese !== name) {
            sList.push(this.selectList[i])
          }
        }
        // 从fastConfigurationInfoEntities 删除
        for (let i = 0; i < this.fastInfo.fastConfigurationInfoEntities.length; i++) {
          if (this.fastInfo.fastConfigurationInfoEntities[i].detectName !== name) {
            fList.push(this.fastInfo.fastConfigurationInfoEntities[i])
          }
        }
        this.selectList = sList
        this.fastInfo.fastConfigurationInfoEntities = fList

        this.updateFastSetting()
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .select-drop-down {
    width: auto;
  }
</style>

